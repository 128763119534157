export default function () {

	$('.m-side-nav:not(.js-side-nav-done)').each(function () {
		var wrapper = $(this);
		var allow = true;
		var title = wrapper.find('.js-title');
		var menu = wrapper.find('.js-menu');

		// click
		title.off('click').on('click', function () {
			if (!allow) return false;
			allow = false;
			title.toggleClass('active');
			menu.slideToggle(250, function () {
				allow = true;
			});
			return false;
		});

		// done
		wrapper.addClass('js-side-nav-done');
	});

}
